import React from "react";

const request = (url, options) => {
  // we can swap out fetch with another library later on if needed
  return fetch(url, options);
}

export const RequestContext = React.createContext();

export const useRequest = () => React.useContext(RequestContext);

export const RequestProvider = ({ children }) => {
  return (
    <RequestContext.Provider value={request}>{children}</RequestContext.Provider>
  );
};
