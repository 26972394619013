import React from "react";
import FormControlLabel from '@proofpoint/fuse-ui/components/FormControlLabel';
import Radio from '@proofpoint/fuse-ui/components/Radio';

const Cartridge = ({cartridge, checked}) => {
  return (
    <FormControlLabel value={cartridge.major_version} checked={checked} control={<Radio />} label={cartridge.major_version} />
  );
};

export default Cartridge;
