import Typography from '@proofpoint/fuse-ui/components/Typography';
import Divider from '@proofpoint/fuse-ui/components/Divider';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = {
  link: {
    color: '#757575', 
    textDecoration: 'none',
    '&:visited': {
      color: '#757575',
    },
  },
}

class Footer extends React.Component {
  constructor() {
    super();
    this.currentYear = new Date().getFullYear(); 
    this.version = process.env.REACT_APP_VERSION;
  }
  render() {
    const { classes } = this.props;
    return(
      <>
        <Divider light />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2" gutterBottom >
            <a href="https://www.cloudmark.com" className={classes.link}>©{this.currentYear} Cloudmark Inc</a>. All rights reserved
          </Typography>
          <Typography variant="body2" gutterBottom>
            <a href="https://www.proofpoint.com/us/legal/privacy-policy" className={classes.link}>Privacy Policy</a><br/>
            {this.version}
          </Typography>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(Footer)
