import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import Loading from './components/Loading'

const styles = {
  iconButton: {
    marginRight: 16,
  },
};

const Auth = ({auth0, children}) => {

    const {
      isLoading,
      isAuthenticated,
      error,
    } = auth0;

    if (isLoading) {
      return (
        <Loading />
      );
    }
    if (error) {
      return <div>Oops... {error.message}</div>;
    }
    if (isAuthenticated) {
      return children;
    }
}

export default withStyles(styles)(withAuth0(Auth));

