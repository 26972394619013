import {useState, useEffect} from 'react';
import CircularProgress from '@proofpoint/fuse-ui/components/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop'

const inlineStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '10px 0',
  zIndex: '9999',
};

const Loading = ({show=true}) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(()=>{
    setOpen(show);
  }, [show]);
  return (
    <Backdrop
      style={inlineStyle}
      open={open}
      onClick={handleClose}
    >
      <div style={inlineStyle}>
        <CircularProgress size="large"/>
      </div>
    </Backdrop>
  );
};

export default Loading;
