import React from 'react';
import { postAssertionRequest } from '../requests';

const Assertion = React.createContext();

const REQ_ASSERT_SPAM   = "APP/ACTION/ASSERT_SPAM";
const REQ_ASSERT_LEGIT  = "APP/ACTION/ASSERT_LEGIT";
const REQ_ASSERT_RESET  = "APP/ACTION/ASSERT_RESET";
// these should really be imported from a common location
const REQ_AUTHN         = "APP/ACTION/AUTHN";
const REQ_LOADING       = "APP/ACTION/LOADING";
const REQ_COMPLETE      = "APP/ACTION/COMPLETE";
const REQ_ERROR         = "APP/ACTION/ERROR";
const AUTHN_ERROR       = "APP/ACTION/AUTHN_ERROR";

export const assertSpam  = (results, ids) => ({type: REQ_ASSERT_SPAM, results, ids});
export const assertLegit = (results, ids) => ({type: REQ_ASSERT_LEGIT, results, ids});
export const assertReset = ()             => ({type: REQ_ASSERT_RESET});

export const initAssertState = {
  done: false,
  loading: false,
  error: null,
  requests: [],
  responses: []
};

export const assertReducer = (state = initAssertState, action) => {
  let requests;
  switch (action.type) {
    case REQ_ASSERT_SPAM:
      requests = [];
      action.ids.forEach(id => requests.push(postAssertionRequest("spam", action.results, id)));
      return {...state, requests, done: false, error: null};
    case REQ_ASSERT_LEGIT:
      requests = [];
      action.ids.forEach(id => requests.push(postAssertionRequest("legitimate", action.results, id)));
      return {...state, requests, done: false, error: null};
    case REQ_AUTHN:
    case REQ_LOADING:
      return {
        ...state,
        loading: true
      };
    case REQ_ERROR:
      state.error = action.error;
      // Fall through into REQ_COMPLETE
    case REQ_COMPLETE:
      state.responses.push(action.id);
      let done = true;
      for (const request of state.requests) {
        if (!state.responses.includes(request.id)) {
          done = false;
        }
      }
      return {
        ...state,
        done,
        loading: !done
      };
    case AUTHN_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        done: true
      };
    case REQ_ASSERT_RESET:
      return {
        ...initAssertState,
      };
  }
};

export const useAssertion = () => React.useContext(Assertion);

export const AssertionProvider = ({ children, initialState, reducer }) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState);
  return (
    <Assertion.Provider value={[globalState, dispatch]}>{children}</Assertion.Provider>
  );
};


