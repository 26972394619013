import { AnalysisHeader, Message, MsgFile } from './Input';
import { getSupportDescription } from './context/SupportProvider';
import Mail from './Mail'; 

export const BASE_URL = process.env.REACT_APP_INSIGHT_FEATURES_URL;
export const SUPPORT_ADDRESS = process.env.REACT_APP_SUPPORT_ADDRESS;

const createUrl = (base, path) => `${base}${path}`;

const buildRequest = (path, oRequest, id="") => {
  const url = createUrl(BASE_URL, path);
  return {
    url: url,
    params: oRequest,
    id,
  };
};

export const getCartridges = () => buildRequest("/features/v2/cartridges",{ method: "GET", mode:"cors"});

export const getCartridgeVersion = (cv) =>  buildRequest(`/features/v2/cartridges/${cv}`,{ method: "GET", mode:"cors"});

export const getIPDetail = (ip) =>  buildRequest(`/features/v2/ip/${ip.normalize()}`,{ method: "GET", mode:"cors"});

export const getCsiIP = (ip) =>  buildRequest(`/features/v2/csi/${ip.normalize()}`,{ method: "GET", mode:"cors"});

export const getCsiCidr = (cidr) =>  buildRequest(`/features/v2/csi/${cidr.raw_cidr}`,{ method: "GET", mode:"cors"});

export const postAuthorityAnalysis = (analysis) => {
  const payload = `{"analysis": "${analysis.getForcedAnalysisString()}"}`
  return buildRequest("/features/v2/authority-analysis",{ method: "POST", mode:"cors", body: payload});
}

export const postMessageAnalysis = (message, cartridge) => {
  const formData = new FormData();
  formData.append('cartridge', cartridge);
  const content = message.content || message.file
  formData.append('rfc822', content);
  return buildRequest("/features/v2/message",{ 
    method: "POST", 
    mode:"cors", 
    body: formData
  });
}

export const postSupportRequest = async (form, results) => {
  let mail = new Mail();
  mail.setHeader('From', form.contact_email);
  mail.setHeader('Subject', `P4 ${form.issue_summary}`);
  if (results.input instanceof Message) {
    mail.appendAttachment(results.input);
  }

  if (results.input instanceof MsgFile) {
    mail.appendAttachment(results.input);
  }

  if (results.input instanceof AnalysisHeader && form.sample_file.content) {
    mail.appendAttachment(form.sample_file);
  }
  const message_body =`Product: Cloudmark Authority

Contact Name: ${form.contact_name}
Contact Email: ${form.contact_email}
Contact Company: ${form.contact_company}
Contact Phone: ${form.contact_phone}

Summary
${form.issue_summary}

Description
${form.additional_details}

${getSupportDescription(results)}
`;
  try {
    const b64Body = await mail.base64(message_body);
    const payload = {header: mail.getHeadersArray(), from: form.contact_email, recipient: [SUPPORT_ADDRESS], body: mail.writeBody(b64Body)};
    return buildRequest("/support/v1/email",{ method: "POST", mode:"cors", headers: {"content-type": "application/json"}, body: JSON.stringify(payload)});
  } catch (err) {
    console.log(err);
  }
}

export const postAssertionRequest = (action, results, id) => {
  const formData = new FormData();
  let customerId = id.customerId
  let content;
  switch (true) {
    case results.input instanceof Message:
      content = results.input.content;
      break;
    case results.input instanceof MsgFile:
      content = results.input.file;
      break;
    case results.input instanceof AnalysisHeader:
      customerId += "-hdr";
      content = `To: <nobody@cloudmark.com>\r\n`
      content += `From: <${id.reporter}@feedback.cloudmark.com>\r\n`
      content += `Date: ${(new Date).toUTCString()}\r\n`
      content += `Subject: API Submiited Analysis String (${Date.now().toString()})\r\n`
      content += `Content-type: text/plain; charset="UTF-8"\r\n`
      content += `X-Portal-Reporter: ${id.reporter}\r\n`
      content += `X-Portal-Analysis: ${results.input.getFoldedAnalysisString()}\r\n`
      content += `X-Portal-Authority: ${results.data.cartridgeVersion}\r\n`
      content += `\r\n`
      content += `no content\r\n`;
      break;
    default:
      console.error("Invalid input type to block/unblock.");
      return;
  }
  formData.append('email', content);
  formData.append('reporter', id.reporter);
  formData.append('customerId', customerId);
  const uri = `/feedback/v2/email/${action}`;
  return buildRequest(uri,{ 
    method: "POST", 
    mode:"cors", 
    body: formData
  }, id.reporter);
}
