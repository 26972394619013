import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@proofpoint/fuse-ui/components/Button';
import TextArea from '@proofpoint/fuse-ui/components/TextArea';
import FormControlLabel from '@proofpoint/fuse-ui/components/FormControlLabel';
import FormControl from '@proofpoint/fuse-ui/components/FormControl';
import Switch from '@proofpoint/fuse-ui/components/Switch';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import Input, {MsgFile} from '../Input';
import { useResults, setInput } from '../context/ResultsProvider';
import { assertReset, useAssertion } from '../context/AssertionProvider';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingTop: '6rem',
  },
  switchBase: {
    '& > span': {
      top: '-3px',
      left: '-2px',
    },
  },
  file: {
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  spacing: {
    marginBottom: '1rem',
  },
  primaryCardTitle: {
    fontSize: 24,
    color: "#01579b",
  },
};

const defaultValue = `Examples:
--- IP Address ---
123.45.67.89

--- CIDR ---
123.45.67.89/26

--- Email ---
To: someone@cloudmark.com
From: someoneelse@cloudmark.com
Date: Fri, 18 Mar 2022 16:44:43 +0000
Subject: This is a Test

Message content follows headers.

--- X-Authority-Analysis ---
X-Authority-Analysis: v=2.4 cv=W8sr6Dak c=1 sm=1 tr=0 ts=6213e335
 a=BkS+TL1DiTwJ97RBfomjaA==:117 a=BkS+TL1DiTwJ97RBfomjaA==:17
 a=M51BFTxLslgA:10 a=fmD_JHji_u0A:10 a=ajSFiXO9XQIA:10 a=o8Yc47e7OvsA:10
 a=TZmrJTXDgRUA:10 a=v82YwT0Dd1VFajEJav8A:9 a=cTDYqaVPAAAA:8 a=PNK8LjZIAAAA:20
 a=dDmJAdL4ATVIK3T55ZQA:9 a=QEXdDO2ut3YA:10 a=xEq5cxCPeIYA:10

--- Message Content ---
Message content with no headers`;

const AnalysisForm = ({classes, triggerInputChange, triggerSubmit, triggerFileSubmit, selectedCartridge}) => {

  const [, setBody] = useState("");
  const [unroll, setUnroll] = useState(false);
  const [inputType, setInputType] = useResults();

  const handleChange = (event, original) => {
    const input = (original === undefined) ? Input.detectType(event.target.value) : Input.detectType(original);
    triggerInputChange(input);
    setBody(event.target.value);
  };

  const handleUnroll = (event) => {
    setUnroll(event.target.checked);
  };

  const handlePaste = (event) => {
    if (unroll) {
      event.preventDefault();
      const original = event.clipboardData.getData('text/plain');
      const content = unescapeString(original);
      event.target.setRangeText(content, event.target.selectionStart, event.target.selectionEnd);
      if (event.target.value !== content) {
        //mixed paste 
        alert("Mixing of pasted content when 'translate escape characters' is enabled will lead to unpredicatble results.");
      }
      handleChange(event, original)
    }
  };
   
  const handleSubmit = (event) => {
    triggerSubmit(inputType.input);
    event.preventDefault();
  };

  const unescapeString = (s) => {
    let copy = s.slice();
    return copy.replace(/\\a|\\b|\\v|\\n|\\r|\\f|\\t/g, x=>{  
      try {
        const ret = JSON.parse('"'+x+'"');
        return ret;
      } 
      catch (e) { //\a, \b, & \v have no printed value
        return '';
      }
    });
  };

  const cancel = (event) => {
    document.getElementById("analysisForm").reset();
    document.getElementById("analysis").value = "";
    setBody("");
    triggerInputChange(null);
  }

  const handleFile = (event) => {
    const input = Input.detectType(event.target.files[0]);
    setInputType(setInput(input));
    input.readFile().then(text => {
      input.setContent(text);
      triggerInputChange(input);
    })
    .catch(e => {
      log.error(e);
    });
  };

  return (<Card>
      <CardHeader classes={{title:classes.primaryCardTitle}} title="Analyze an IP address, CIDR block, email message or Authority Header" />
      <CardContent>
        <form id="analysisForm">
          <FormControl>
            <FormGroup>
              <FormControlLabel className={classes.spacing}
                control={<Switch checked={unroll} onChange={handleUnroll} className={classes.switchBase}/>}
                label="Translate escape characters (\a \b \r \n \t \v \f) from pasted text" />
            </FormGroup>
          </FormControl>
          <TextArea
            fullWidth={true}
            id="analysis"
            placeholder={defaultValue}
            rows={8}
            maxRows={12}
            helperText="Alternatively, use the file upload button below to analyze a message"
            disabled={inputType.input instanceof MsgFile}
            onPaste={handlePaste}
            onChange={handleChange} />
          <Paper className={classes.file}>
            <input id="analysisFile" type="file" name="file" onChange={handleFile} />
          </Paper>
          <CheckButton onClick={handleSubmit} result={inputType} />
          <Button onClick={cancel} variant="outlined" disabled={inputType.input===null}>Clear</Button>
          <CountdownMessage />
        </form>
      </CardContent>
     </Card>
  );
};

const CountdownMessage = () => {
  const [assertion] = useAssertion();
  if (assertion.done) {
    return(<Typography variant="body1">
        It may take several minutes for submitted feedback to be processed and incorporated into the Cloudmark Network Feedback System. Please wait for the timer to elapse before rechecking the same content.
      </Typography>);
  }
  return null;
}

const CheckButton = ({onClick, result}) => {
  const timeout = 60;
  const [assertion, dispatch] = useAssertion();
  const [secs, setSecs] = useState(timeout);
  const [counter, setCounter] = useState();
  const [showRecheck, setShowRecheck] = useState(false);
  let type = (result?.input?.label) ? ` ${result.input.label}` : "";
  let label = (assertion.done) ? `Recheck${type} [${secs}s]` : `Check${type}`;
  if (showRecheck && result.done) {
    label = `Recheck${type}`;
  }
  let disabled = (result.input===null) || (assertion.done && secs > 0);

  useEffect(() => {
    if (assertion.done && secs > 0) {
      setCounter(setInterval(() => {
        setSecs(secs => secs - 1);
      }, 1000));
      setShowRecheck(false);
    }
    else {
      clearInterval(counter);
      dispatch(assertReset());;
      setSecs(timeout);
      setShowRecheck(true);
    }
    return () => clearInterval(counter);
  }, [assertion.done, secs, showRecheck]);

  return (
    <Button onClick={onClick} disabled={disabled}>{label}</Button>
  );

};

export default withStyles(styles)(AnalysisForm);
