import React from 'react';
import Alert from '@proofpoint/fuse-ui/components/Alert';
import Collapse from '@material-ui/core/Collapse'

const Flash = ({severity, message, open, setOpen}) => {
  const closed = {severity:"alert", message:"", open:false};
  if (!message || message.length==0) {
    return <></>;
  } 
  return (
    <Collapse in={open} >
      <Alert severity={severity} onClose={()=>{setOpen(closed);}} >
        {message}
      </Alert>
    </Collapse>);
};

export default Flash;
