import React from 'react';
import { getCartridges } from '../requests';

const Cartridges = React.createContext();

const PROCESS_CARTRIDGES = "APP/ACTION/PROCESS_CARTRIDGES";
const PROCESS_VERSION    = "APP/ACTION/PROCESS_VERSION";
const SELECT_CARTRIDGE   = "APP/ACTION/SELECT_CARTRIDGE";
const REQ_AUTHN          = "APP/ACTION/AUTHN";
const REQ_LOADING        = "APP/ACTION/LOADING";
const REQ_COMPLETE       = "APP/ACTION/COMPLETE";
const REQ_ERROR          = "APP/ACTION/ERROR";
const AUTHN_ERROR        = "APP/ACTION/AUTHN_ERROR";


export const cartridgesReducer = (state = initResultsState, action) => {
  switch (action.type) {
    case PROCESS_CARTRIDGES:
      return {
        ...state,
        request: getCartridges(),
        error: null,
        done: false
      };
    case REQ_AUTHN:
    case REQ_LOADING:
      return {
        ...state,
        loading: true
      };
    case REQ_ERROR:
      state.error = action.error;
      // Fall through into REQ_COMPLETE
    case REQ_COMPLETE:
      return {
        ...state,
        done: true,
        loading: false,
        data: action.data
      };
    case AUTHN_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        done: true
      };
  }
};

export const useCartridges = () => React.useContext(Cartridges);

export const CartridgesProvider = ({ children, initialState, reducer }) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState);
  return (
    <Cartridges.Provider value={[globalState, dispatch]}>{children}</Cartridges.Provider>
  );
};


