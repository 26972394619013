import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@proofpoint/fuse-ui/components/Menu';
import MenuItem from '@proofpoint/fuse-ui/components/MenuItem';
import Button from '@proofpoint/fuse-ui/components/Button';

const styles = {
  iconButton: {
    marginRight: 16,
  },
};

class AccountMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }
  
  render() {
    const {
      user,
      isAuthenticated,
      logout,
    } = this.props.auth0;
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    if (isAuthenticated){
      return (
        <div>
        <Button
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={this.handleMenu}
          icon={<AccountCircle />}
          iconPosition="left"
        >
            {user.name}
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={() => {logout({ returnTo: window.location.origin });}}>Log out</MenuItem>
        </Menu>
        </div>
      );
    }
    else {
      return <div></div>
    }
  }
}

export default withStyles(styles)(withAuth0(AccountMenu));

