import React from 'react';
import { useResults } from '../context/ResultsProvider';
import { IpRow }from './IpAnalysis'

const CidrAnalysis = ({hide}) => {
  const [results] = useResults();
  if (!results.show) return null;
  let cidr = []
  for (const ip of results.input) {
    cidr.push(<IpRow key={ip.raw_ip} ip_address={ip.raw_ip} state={results} hide={hide} />)
  }
  return cidr
}

export default CidrAnalysis;
