import React from 'react';

export const initSelectedCartridgeState  = {
  show: false,
  selectedCartridge: "",
  done: false,
  loading: false,
  error: null,
  data :null,
  request: null
};

const CartridgeVersion = React.createContext();

export const useCartridgeVersion = () => React.useContext(CartridgeVersion);

export const CartridgeVersionProvider = ({ children, initialState, reducer }) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState);
  return (
    <CartridgeVersion.Provider value={[globalState, dispatch]}>{children}</CartridgeVersion.Provider>
  );
};


