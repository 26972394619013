import React from 'react';
import Cartridges from './Cartridges';
import { useResults } from '../context/ResultsProvider';
import {IP, CIDR, AnalysisHeader, Message, MsgFile} from '../Input';
import Typography from '@proofpoint/fuse-ui/components/Typography';

const ContextualHelp = () => {
  const [state] = useResults();
  switch (true) {
    case state.input instanceof IP:
    case state.input instanceof CIDR:
      return <IpHelp/>
    case state.input instanceof AnalysisHeader:
      return <AnalysisHeaderHelp />
    case state.input instanceof Message:
    case state.input instanceof MsgFile:
      return <CartridgesHelp />;
    default:
      return <DefaultHelp />;
  }
}

const DefaultHelp = () => {
  return (<Typography variant="body1">
      Enter an IP address, CIDR block (maximum /24) Authority analysis header or message in the form to the left. This tool will automatically detect the type of content entered.
    </Typography>);
}

const IpHelp = () => {
  return (<Typography variant="body1">
      CAT will return the current status of each IP. An IP status may be "Poor", "Suspect", "Mail Forwarder",
      or "Unknown". Messages from Poor IPs are usually spammy in Authority. Poor
      and Suspect IPs may be blocked or throttled using CSI, depending on your MTA
      policies. CAT allows you to submit a remediation request to Cloudmark for IPs
      that are in Poor status if you disagree with the status.
    </Typography>);
}

const CartridgesHelp = () => {
  return (
    <React.Fragment>
      <Typography variant="subtitle1">Select a cartridge to run analysis against.</Typography>
      <Cartridges/>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }}>Please note:</Typography>
      <Typography variant="body1" paragraph>7MB is the maximum pasted message size.</Typography>
      <PasteHelp tool="Check Message Content"/>
    </React.Fragment>);
}

const AnalysisHeaderHelp = () => {
  return (
    <React.Fragment>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }}>Please note:</Typography>
      <PasteHelp tool="Check Analysis Header" />
    </React.Fragment>);
}

const PasteHelp = ({tool}) => {
  return <Typography variant="body1" color="secondary" paragraph>If you are copying from a terminal window via "more", "less" or "cat" there is a possibility that tab characters have been converted to multiple single-space characters. This may result in calculation of an incorrect set of fingerprints which may impact the rendered message verdict. Please use a text editor like "vi" to view the message before copying and pasting into the {tool} tool.</Typography>;
}

export default ContextualHelp;
