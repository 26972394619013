import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import { AnalysisHeader, Message, MsgFile } from '../Input';
import { useResults } from '../context/ResultsProvider';
import { assertSpam, assertLegit, useAssertion } from '../context/AssertionProvider';
import useApiResult from '../useApiResult';
import Button from '@proofpoint/fuse-ui/components/Button';

export const AssertButton = ({setFlash}) => {
  const [results] = useResults();
  const [assertResults, dispatch] = useAssertion();
  const {user} = useAuth0();
  const requests = assertResults.requests;
  useApiResult(requests, dispatch);
  
  useEffect(() => {
    if (assertResults.error !== null) {
      let error_message
      try {
        const jr = JSON.parse(assertResults.error);
        error_message  = (Object.hasOwn(jr, 'message')) ? jr.message : jr.stringify();
      } catch (e) {
        error_message = "Oops! Something went wrong";
        console.error(e);
      }
      setFlash({severity:"error", message:error_message, open:true});
      return;
    }
    if (assertResults.done) {
      setFlash({severity:"success", message:"Feedback successfully submitted to Cloudmark Network Feedback System", open:true});
    }
  }, [assertResults.done, assertResults.error, setFlash])
  
  if (user.scope === undefined) return null;

  let scopes = [];
  user.scope.split(' ').forEach(scope => scopes.push(scope.split(':')));

  const hasScope = (rw, type, action) => {
    //Example scope: ['write', 'cat', 'message', 'block', '3']
    for (const scope of scopes) {
      if (scope.length != 5) continue; //some other scope
      if (scope[0] === rw && scope[2] === type && scope[3] === action) return scope[4];
    }
    return 0;
  }

  const setSpam = (limit) => {
    doAssert(assertSpam, limit);
  }

  const setLegit = (limit) => {
    doAssert(assertLegit, limit);
  }

  const doAssert = (fn, limit) => {
    let ids = [];
    for (let i=0; i < limit; i++) {
      let id = {}
      id.customerId = user.email.substring(0, user.email.indexOf('@'));
      for (const strip of ["-api", "-portal", "-cat"] ) {
        if (id.customerId.endsWith(strip)) {
          id.customerId = id.customerId.substring(0, id.customerId.indexOf(strip))
        }
      }
      id.customerId += "-cat";
      id.reporter = user.nickname + i.toString();
      ids.push(id);
    }
    dispatch(fn(results, ids));
  };


  if (!(results.input instanceof Message || results.input instanceof MsgFile || results.input instanceof AnalysisHeader)) {
    return null;
  }
  
  if (results.loading || assertResults.loading) {
    return (<Button loading={true} style={{marginLeft:'20px'}}>Loading</Button>);
  }

  if (results.error !== null) {
    return null;
  }
  
  

  if (results.done && results.data.algorithmResults !== undefined) {
    const type = (results.input instanceof Message || results.input instanceof MsgFile)? "message" : "header";
    const action = (results.data.algorithmResults.filter(e => e.activeThreat).length > 0)? "unblock" : "block";
    const limit = hasScope('write', type, action);
    if (limit === 0) return null;
    const type_label = (type === "message")? "Message" : "Analysis String";
    const action_label = (action === "unblock")? "Unblock" : "Block";
    const icon = (action === "block")? <ThumbDownAltIcon htmlColor={'#fafafa'}/> : <ThumbUpAltIcon htmlColor={'#fafafa'}/>;
    const assert = () => (action === "block")? setSpam(limit) : setLegit(limit);
    const buttonLabel = `${action_label} ${type_label}`;
    if (assertResults.done) {
      return <Button disabled={true} icon={icon} style={{marginLeft:'20px'}}>{buttonLabel}</Button>
    }
    return(
      <Button onClick={assert} aria-label={buttonLabel} darkBackgroundTheme="primary" icon={icon} style={{marginLeft:'20px'}}>{buttonLabel}</Button>
    );
  }
  return null;
}

const initForm = {contact_name: "", contact_email: "", contact_company: "", issue_summary: "", additional_details: ""};

