class Mail {
  #headers = {};
  #boundary;
  #attachments = [];
  #fileReader;
  #resolve;
  #reject;
  #CRLF = "\r\n"; 
  #MAX = 78;
  #preamble = `This is a multipart message. If you're reading this, you 
  might want to consider changing to a mail reader that understands 
  how to properly display multipart message.`;


  constructor() {
    this.#boundary = this.uuid();
    this.setHeader("Date", (new Date).toUTCString());
    this.setHeader("Subject", "No subject");
    if (typeof FileReader != "undefined") {
      this.#fileReader = new FileReader();
      this.#fileReader.onload = this.onload(this);
      this.#fileReader.onerror = this.onerror(this);
    }
  }

  onload(self) {
    return function() {
      let str = self.#fileReader.result;
      self.#resolve(str.substring(str.indexOf(",")+1));
    }
  }

  onerror(self) {
    return function() {
      let err = self.#fileReader.error;
      self.#reject(err);
    }
  }

  readData(str) {
    const encoder = new TextEncoder();
    const blob = new Blob([encoder.encode(str)]);
    this.#fileReader.readAsDataURL(blob);
  }

  base64(str) {
    return new Promise((resolve, reject) => {
      this.#resolve = resolve;
      this.#reject = reject;
      this.readData(str);
    });
  }
 
  asciiEncode(str) {
    let newstr = "";
    for (let i = 0; i < str.length; i++) {
      if (str.codePointAt(i) < 32) continue; //control char
      if (str.codePointAt(i) < 127) newstr += str[i];
      else {
        let char = `U+${str.codePointAt(i)};`;
        newstr += char.replace('U+', '&#');
      }
    }
    return newstr;
  }
 
  setHeader(key, value) {
    this.#headers[this.normalize(key)] = this.asciiEncode(value);
    return this;
  }

  getHeader(key) {
    return this.#headers[this.normalize(key)];
  }

  appendAttachment(attachment) {
    this.#attachments[this.#attachments.length] = attachment
  }

  // normalize - Normalizes header keys such that x-custom-header becomes X-Custom-Header
  normalize(key) {
    let normalized = "";
    for(let i = 0; i < key.length; i++) {
      if (i == 0 || key[i-1] == "-") {
        normalized += key[i].toUpperCase();
      } else {
        normalized += key[i].toLowerCase();
      }
    }
    return normalized; 
  }

  // wrap will fold a string at $max characters. It will attempt to fold at the 
  // nearest space before $max. If there are no spaces (e.g. base64) folding 
  // happens at $max.
  wrap(str, max) {
    // We want to remove any lone \r or \n characters and only have #CRLFs in the text
    // Do this by removing all \r chars then replacing remaining \n with #CRLF
    str = str.replaceAll("\r", "").replaceAll("\n", this.#CRLF);
    let lines = []
    for (const line of str.split(this.#CRLF)) {
      if (line.length <= max) {
        lines[lines.length] = line;
        continue;
      }
      // any spaces?
      if (str.indexOf(" ") < max && str.indexOf(" ") > -1) {
        lines[lines.length] = this.wrapText(line, " ", max);
      } else if (str.indexOf("\t") < this.#MAX  && str.indexOf("\t") > -1) {
        lines[lines.length] = this.wrapText(line, "\t", max);
      } else {
        lines[lines.length] = this.wrapString(line, max);
      }
    }
    return lines.join(this.#CRLF);
  }

  wrapString(str, max) {
    let first_pos = 0;
    let last_pos = first_pos + max;
    let new_str = "";
    while (first_pos <= str.length) {
      new_str += str.slice(first_pos, last_pos) + this.#CRLF;
      first_pos = last_pos;
      last_pos = first_pos + max;
    }
    return new_str;
  }

  wrapText(str, splitter, max) {
    let wrapped = "";
    let wrapped_length = 0;
    for (const word of str.split(splitter)) {
      if (word.length > max) {
        let wrapped_word = this.#CRLF + this.wrapString(word, max);
        wrapped += wrapped_word
        continue;
      }
      if ((wrapped.length - wrapped_length + word.length) > max) {
        wrapped_length = wrapped.length;
        wrapped += this.#CRLF + word;
      } else if (wrapped.length == 0) {
        wrapped += word;
      } else {
        wrapped += splitter + word;
      }
    }
    return wrapped;
  }

  fold(line) {
    line = line.trim();
    if (line.length < this.#MAX) {
      return line;
    }

    let folded = "";
    let folded_length = 0;
    line = line.replaceAll(/[\v\s]/gm, " "); // normalize whitespace
    for (const word of line.split(' ')) {
      if ((folded.length - folded_length + word.length) > this.#MAX) {
        folded_length = folded.length;
        folded += this.#CRLF + " " + word;
      } else if (folded == "") {
        folded += word;
      } else {
        folded += " " + word;
      }
    }
    return folded.trim();
  }

  uuid() {
    try {
      return window.crypto.randomUUID();
    } catch (e) {
      console.log(`No client support for crypto function: ${e}`);
      const rand = (n) => {
        const s = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let r = ""
        while (r.length < n) {
          r += s[Math.floor(Math.random() * s.length)];
        }
        return r;
      }
      return `${rand(8)}-${rand(4)}-${rand(4)}-${rand(4)}-${rand(12)}`;
    }
  }

  getHeadersArray() {
    if (this.#attachments.length > 0) {
      this.setHeader("Content-Type", `multipart/mixed; boundary=${this.#boundary}`) 
    } else {
      this.setHeader("Content-Type", 'text/plain; charset="utf-8"');
      this.setHeader("Content-transfer-encoding", "base64");
    }
    let out = [];
    for (let header of Object.keys(this.#headers)) {
      let line = this.writeHeader(header, this.#headers[header]);
      line = this.fold(line);
      out[out.length] = line;
    }
    return out;
  }

  writeHeaders() {
    return this.getHeadersArray().join(this.#CRLF);
  }

  writeHeader(key, value) {
    return `${this.normalize(key)}: ${value}${this.#CRLF}`
  }

  createBoundary(id) {
    return `${this.#CRLF}--${id}${this.#CRLF}`;
  }

  closeBoundary(id) {
    return `${this.#CRLF}--${id}--${this.#CRLF}`;
  }

  writeBody(b64Body) {
    let out = "";
    if (this.#attachments.length > 0) {
      const inner = this.uuid();
      out = this.#preamble + this.#CRLF
      out += this.createBoundary(this.#boundary);
      out += this.writeHeader("Content-Type", "text/plain; charset=utf-8");
      out += this.writeHeader("Content-Disposition","inline");
      out += this.writeHeader("Content-Description", "support message");
      out += this.writeHeader("Content-transfer-encoding", "base64");
      out += this.#CRLF;
      out += this.wrap(b64Body, 76);
      out += this.#CRLF;
      out += this.createBoundary(this.#boundary);
      out += this.writeHeader("Content-Type", `multipart/mixed; boundary=${inner}`);
      out += this.writeHeader("Content-Disposition","attachment");
      out += this.writeHeader("Content-Description", "multipart");
      out += this.#CRLF;
      for (const i in this.#attachments) {
        const attachment = this.#attachments[i];
        out += this.createBoundary(inner);
        out += this.writeHeader("Content-Type", attachment.type);
        out += this.writeHeader("Content-Disposition","attachment");
        out += this.writeHeader("Content-Description", attachment.name);
        out += this.writeHeader("Content-transfer-encoding", "base64");
        out += this.#CRLF;
        out += this.wrap(attachment.base64, 76);
        out += this.#CRLF;
      }
      out += this.closeBoundary(inner);
      out += this.closeBoundary(this.#boundary);
    } else {
      out = this.wrap(b64Body, 76);
    } 
    return out;
  }

  writeMessage(body) {
    return `${this.writeHeaders()}${this.#CRLF}${this.writeBody(body)}`;
  }
}

//module.exports = Mail; // uncomment this line and comment out the next to run jest tests
export {Mail as default};
