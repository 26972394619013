import React, { useState } from 'react';
import FuseTheme from '@proofpoint/fuse-ui/components/FuseTheme';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { withStyles } from '@material-ui/core/styles';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Auth from './Auth';
import AppBar from './components/AppBar';
import AnalysisForm from './components/AnalysisForm';
import ContextualHelp from './components/ContextualHelp';
import Footer from './components/Footer';
import Loading from './components/Loading';
import Flash from './components/Flash';
import {InputType, IP, CIDR, AnalysisHeader, Message, MsgFile} from './Input';
import Results from './components/Results';
import { useCartridgeVersion } from './context/CartridgeVersionProvider';
import { useResults, processAnalysis, processCsiIp, processMessage, processCsiCidr, processVersion, setInput, reset } from './context/ResultsProvider';
import { useAssertion, assertReset } from './context/AssertionProvider';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingTop: '3rem',
  },
  switchBase: {
    top: '-4px',
    left: '-2px',
  },
  secondaryCardTitle: {
    fontSize: 20,
    color: "#37474f",
    lineHeight: "20px",
  },
};
  
const App = ({classes}) => {
  const [flash, setFlash] = useState({severity:"notice", message:"", open:false});
  const [cancel, setCancel] = useState(null);
  const [cvState, cvDispatch] = useCartridgeVersion();
  const [, dispatch] = useResults();
  const [, assert] = useAssertion();
  const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILESIZE_MB)*(1024**2);

  const triggerInputChange = (input) => {
    clearTimeout(cancel);
    if (input instanceof InputType || input === null) {
      setCancel(setTimeout(() => dispatch(setInput(input)), 300));
      assert(assertReset());
    }
    if (input === null) {
      dispatch(reset());
      assert(assertReset());
    }
    if (input?.analysis?.cartridge_version != null) {
      cvDispatch(processVersion(input.analysis.urlEncodedCV()));
    }
    if (input?.inner?.analysis?.cartridge_version != null) {
      cvDispatch(processVersion(input.inner.analysis.urlEncodedCV()));
    }
  };

  const triggerSubmit = (input) => {
    setFlash({open:false});
    switch (true) {
      case input instanceof IP:
        dispatch(processCsiIp(input));
        break;
      case input instanceof CIDR:
        if (!input.in_range) {
          setFlash({severity:"warning", message:`Maximum allowed size for a CIDR is "/${process.env.REACT_APP_MAX_CIDR_SLASH}"`, open:true});
          return;
        }
        dispatch(processCsiCidr(input));
        break;
      case input instanceof Message:
        if (cvState.selectedCartridge === "") {
          setFlash({severity:"warning", message:"Please select a cartridge before submitting a message for analysis", open:true});
          return;
        }
        dispatch(processMessage(input, cvState.selectedCartridge));
        break;
      case input instanceof AnalysisHeader:
        dispatch(processAnalysis(input));
        break;
      case input instanceof MsgFile:
        if (cvState.selectedCartridge === "") {
          setFlash({severity:"warning", message:"Please select a cartridge before submitting a message for analysis", open:true});
          return;
        }
        if (input.file.size > maxFileSize) {
          setFlash({severity:"warning", message:"Maximum file size for a message is "+(maxFileSize/(1024**2))+"Mb", open:true});
          return;
        }
        dispatch(processMessage(input, cvState.selectedCartridge));
        break;
    }
  };
  
  return (
    <div className="App">
      <FuseTheme forceLoad={false} />
      <AppBar />
      <Container className={classes.container} maxWidth="lg">
        <Auth>
          <Grid container spacing={6}>
            <Grid item={true} xs={12}>
              <Flash severity={flash.severity} message={flash.message} open={flash.open} setOpen={setFlash}/>
            </Grid>
            <Grid item={true} xs={8}>
              <AnalysisForm triggerInputChange={triggerInputChange} triggerSubmit={triggerSubmit} />
              <Results />
            </Grid>
            <Grid item={true} xs={4}>
              <Card>
                <CardHeader classes={{title:classes.secondaryCardTitle}} title={<div><HelpOutlineIcon color="primary"/> Contextual Help</div>} />
                <CardContent>
                  <ContextualHelp/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item={true} xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </Auth>
      </Container>
    </div>
  );
}


export default withStyles(styles)(withAuthenticationRequired(App, {onRedirecting: () =>  <Loading />}));
