const EngineMap = (id) => {
  const eMap = {
    "8": "Message contains spam URL reported by Cloudmark Global Threat Network",
    "9": "Message contains spam content reported by Cloudmark Global Threat Network",
    "10": "Message contains spam content reported by Cloudmark Global Threat Network",
    "14": "Message attachment contains a virus reported by Cloudmark Global Threat Network",
    "17": "Message sent by IP with spam reputation reported by Cloudmark Global Threat Network",
    "18": "Message contains embedded spam image reported by Cloudmark Global Threat Network",
    "19": "Message contains spam content reported by Cloudmark Global Threat Network",
    "20": "Message contains URL redirector to spam content reported by Cloudmark Global Threat Network",
    "21": "Message contains spam content reported by Cloudmark Global Threat Network",
    "22": "Message contains spam content reported by Cloudmark Global Threat Network"
  }
  if (eMap.hasOwnProperty(id)) {
    return [eMap[id], null];
  }
  return [null, "Unrecognized engine"];

}

export default EngineMap
