import React from "react";
import { withStyles } from '@material-ui/core/styles';
import AccountMenu from './Account';
import { default as WrAppBar } from '@proofpoint/fuse-ui/components/AppBar';

const classes = {
  grow: {
    flexGrow: 1,
  }
};

const AppBar = ({classes}) => {
  return (
      <WrAppBar companyLogo={<img src="./cloudmark_logo.png" width="220" />} productName="Customer Accuracy Tools">
        <div className={classes.grow} />
        <AccountMenu />
      </WrAppBar>
  );
};

export default withStyles(classes)(AppBar);
