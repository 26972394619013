import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { fuseTheme } from '@proofpoint/fuse-ui';
import { Auth0Provider } from '@auth0/auth0-react';
import { RequestProvider } from './context/RequestProvider';
import { ResultsProvider, initResultsState, resultsReducer } from './context/ResultsProvider';
import { CartridgesProvider, cartridgesReducer } from './context/CartridgesProvider';
import { CartridgeVersionProvider, initSelectedCartridgeState } from './context/CartridgeVersionProvider';
import { SupportProvider, supportReducer, initSupportState } from './context/SupportProvider';
import { AssertionProvider, assertReducer, initAssertState } from './context/AssertionProvider';
import App from './App';

const cartInit = {...initResultsState};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={fuseTheme}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        scope={process.env.REACT_APP_AUTH0_SCOPE}
      >
        <RequestProvider>  
          <CartridgesProvider initialState={cartInit} reducer={cartridgesReducer}>
            <CartridgeVersionProvider initialState={initSelectedCartridgeState} reducer={resultsReducer}>
              <ResultsProvider initialState={initResultsState} reducer={resultsReducer}>
                <SupportProvider initialState={initSupportState} reducer={supportReducer}>
                  <AssertionProvider initialState={initAssertState} reducer={assertReducer}>
                    <App />
                  </AssertionProvider>
                </SupportProvider>
              </ResultsProvider>
            </CartridgeVersionProvider>
          </CartridgesProvider>
        </RequestProvider>  
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
