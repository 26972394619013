class Input {
  static ip_rx = /^((1?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(1?\d{1,2}|2[0-4]\d|25[0-5])$/;
  static cidr_rx = /^((1?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(1?\d{1,2}|2[0-4]\d|25[0-5])\/([12]?\d|3[0-2])$/;
  static header_rx = /(^([\x21-\x7E]+):(.*$(?:(?:(?:\r\n)|\r|\n)^[\x09\x20]+.*$)*))((?:(?:\r\n)|\n){2,}.)?/gm;
  static no_content_rx = /\bnc=(?<no_content>[01])\b/;
  static signature_rx = /\b(?<verdict>[ap])=(?<sig>[A-Za-z0-9+\/=_-]{12,28}):(?<engine>\d{1,4})(?::(?<extra>\S*))?\b/;
  static whitelist_rx = /\b(?<whitelist_type>a?wl)=(?<value>(?:(?:host|hdr|env|body):)?\d+)\b/;
  static cartridge_version_rx = /\bcv=(?<cartridge_version>[\w+\/=-]+)\b/;
  static context_rx = /\bcx=(?<context>\S+)\b/;
  static content_class_rx = /\bcc=(?<content>\S+)\b/;
  static scoring_method_rx = /\bsm=(?<scoring_method>[0-4])\b/;
  static truncated_rx = /\btr=(?<truncated>[01])\b/;
  static priority_rx = /\bpo=(?<priority>[01])\b/;
  static complete_rx = /\bc=(?<complete>[01])\b/;
  static bulk_rx = /\bb=(?<bulk>[01])\b/;
  static version_rx = /\bv=(?<version>\d+\.\d+)\b/;
  static timestamp_rx = /\bts=(?<timestamp>[A-Za-z0-9+\/=_-]+)\b/;
  static mask_rx = /\bm=(?<mask>[A-Za-z0-9+\/=_-]{8})\b/;
  static field_name_rx = /^\b(?<field_name>[xX][\x21-\x7E]+):/;
  static minimum_likeness = 0.8;

  static detectType(value) {
    if (value == null || value === "" || value.length < 1) return null;
    if (value instanceof File) return new MsgFile(value)
    if (value.trim().match(this.ip_rx)) {
      return new IP(value.trim())
    }
    if (value.trim().match(this.cidr_rx)) {
      return new CIDR(value.trim());
    }
    const authority_header = this.parseAuthorityHeader(value);
    if (authority_header.analysisLikeness() >= this.minimum_likeness) return authority_header;
    const m = [...value.matchAll(this.header_rx)];
    if (m.length > 0) {
      return this.parseMessage(m, value);
    }
    return new Message(value, false, true);
  }

  static parseMessage(hdrMatches, value) {
    let headers = {};
    let likeness = 0;
    let authority_header = new AnalysisHeader()
    for (const match of hdrMatches) {
      headers[match[2].toLowerCase()] = match[3]
      const test_header = this.parseAuthorityHeader(match[0]);
      if (test_header.analysisLikeness() >= likeness) {
        likeness = test_header.analysisLikeness();
        authority_header = test_header;
      }
      if (match[4]!=undefined && headers.hasOwnProperty("from") && headers.hasOwnProperty("date")) {
        let m = new Message(value, true, false);
        m.SetAnalysis(authority_header)
        return m;
      }
    }
    if (authority_header.analysisLikeness() >= this.minimum_likeness) return authority_header;
    return new Message(value, false, false);
  }

  static parseAuthorityHeader(value) {
    let as = new AnalysisHeader(value);
    as.processRaw()
    return as;
  }

}

class InputType {}

class IP extends InputType {
  label = "IP";
  raw_ip = null;
  constructor(ip) {
    super();
    this.raw_ip = ip;
  }

  normalize() {
    return this.raw_ip.split('.').map(e => parseInt(e).toString()).join('.');
  }
}

class CIDR extends InputType {
  label = "CIDR";
  raw_cidr = null
  in_range = null;
  mask = null;
  ip_parts = [];
  bits = null;
  bit_mask = null;

  constructor(cidr) {
    super();
    this.raw_cidr = cidr;
    const split = this.raw_cidr.split("/");
    this.ip_parts = split[0].split(".");
    this.mask = parseInt(split[1]);
    this.bits = Int32Array.from(this.ip_parts, x => parseInt(x));
    this.bit_mask = ((2**(this.mask))-1<<(32-this.mask))
    this.in_range = (this.mask >= parseInt(process.env.REACT_APP_MAX_CIDR_SLASH));
  }
  
  arrayToInt() {
    let intr = 0;
    for (let i = 0; i < this.bits.length ; i++) {
      intr += this.bits[i] << ((3-i)*8);
    }
    return intr;
  }

  intToArray(n) {
    let arr = [];
    for (let i = 3; i >= 0; i--) {
        arr[i] = n>>(i*8)&255
    }
    return arr.reverse();
  }

  getStartIP() {
    return this.arrayToInt() & this.bit_mask;
  }

  getEndIP() {
    return this.arrayToInt() | ~this.bit_mask;
  }

  length() {
    return this.getEndIP() - getStartIP();
  }

  [Symbol.iterator]() {
    let current = this.getStartIP();
    const end = this.getEndIP(); 

    return {
      next: () => {
        if (current <= end) {
          let ip = new IP(this.intToArray(current).join("."));
          current++;
          return {value: ip, done: false}
        } else {
          return {done: true}
        }
      }
    }
  }
  
}

class AnalysisHeader extends InputType {
  label = "Analysis Header";
  ase_sig = /\b[ap]=QhnmdGnfZl10ibMM-eB4:22\b/
  no_content = null;
  signatures;
  whitelist;
  cartridge_version = null;
  context = null;
  content_class = null;
  scoring_method = null;
  truncated = null;
  priority = null;
  complete = null;
  bulk = null;
  version = null;
  timestamp = null;
  mask = null
  field_name = null;
  extra;
  raw_value = null;
  analysis_string = null;
  missing = [];
  likeness = 0;

  constructor(raw) {
    super();
    this.raw_value = raw;
    this.whitelist = new Array();
    this.signatures = new Array();
    this.extra = new Array();
  }

  processRaw() {
    const parts = this.raw_value.trim().replaceAll(/[\n\r\s]+/gm, " ").split(" ");

    for (const part of parts) {
      const nc = part.match(Input.no_content_rx);
      if (nc != null) {
        this.no_content = nc.groups.no_content;
        continue;
      }

      const sig = part.match(Input.signature_rx);
      if (sig != null) {
        this.appendSig(sig);
        continue;
      }

      const wl = part.match(Input.whitelist_rx);
      if (wl != null) {
        this.whitelist.push({type:wl.groups.whitelist_type, value:wl.groups.value});
        continue;
      }

      const cv = part.match(Input.cartridge_version_rx);
      if (cv != null) {
        this.cartridge_version = cv.groups.cartridge_version;
        continue;
      }

      const cx = part.match(Input.context_rx);
      if (cx != null) {
        this.context = cx.groups.context;
        continue;
      }

      const cc = part.match(Input.content_class_rx);
      if (cc != null) {
        this.content_class = cc.groups.content_class;
        continue;
      }

      const sm = part.match(Input.scoring_method_rx);
      if (sm != null) {
        this.scoring_method = sm.groups.scoring_method;
        continue;
      }

      const tr = part.match(Input.truncated_rx);
      if (tr != null) {
        this.truncated = tr.groups.truncated;
        continue;
      }

      const po = part.match(Input.priority_rx);
      if (po != null) {
        this.priority= po.groups.priority;
        continue;
      }

      const c = part.match(Input.complete_rx);
      if (c != null) {
        this.no_content = c.groups.complete;
        continue;
      }

      const b = part.match(Input.bulk_rx);
      if (b != null) {
        this.bulk = b.groups.bulk;
        continue;
      }

      const v = part.match(Input.version_rx);
      if (v != null) {
        this.version = v.groups.version;
        continue;
      }

      const ts = part.match(Input.timestamp_rx);
      if (ts != null) {
        this.timestamp = ts.groups.timestamp;
        continue;
      }

      const m = part.match(Input.mask_rx);
      if (m != null) {
        this.mask = m.groups.mask;
        continue;
      }

      const field_name = part.match(Input.field_name_rx);
      if (field_name != null) {
        this.field_name = field_name.groups.field_name;
        continue;
      }

      this.extra.push(part)
    }

  }

  appendSig(match) {
    this.signatures.push({
      signature: match[0],
      verdict: match.groups.verdict,
      sig: match.groups.sig,
      engine: match.groups.engine,
      extra: match.groups.extra,
    });
  }

  urlEncodedCV() {
    return this.cartridge_version.replace('/','_').replace('+','-');
  }

  isCompleteAnalysisString() {
    if (this.cartridge_version === null) {
      return false;
    }
    if (this.scoring_method === null) {
      return false;
    }
    if (this.version === null) {
      return false;
    }
    if (this.complete === null) {
      return false;
    }
    if (parseFloat(this.cartridge_version) > 2 && this.truncated === null) {
      return false;
    }
    return !(this.whitelist.length === 0 && this.signatures.length === 0 && this.no_content === null);
  }

  containsASESignature() {
    return this.ase_sig.test(this.analysis_string); 
  }

  analysisLikeness() {
    if (this.likeness > 0) return this.likeness;
    let likeness = 0;
    const as = this.getAnalysisString();
    if (as.length == 0) return 0;
    if (this.signatures.length < 1) return 0;
    const surplus = this.extra.join(" ").length; 
    if (surplus < 1) return 1;
    this.likeness = as.length/(as.length+surplus);
    return this.likeness;
    
  }

  getAnalysisString() {
    if (this.analysis_string !== null) return this.analysis_string;
    if (this.raw_value === null) return "";

    let pristine = [];
    
    for (const sig of this.signatures) {
      if (sig.extra) pristine.push(`${sig.verdict}=${sig.sig}:${sig.engine}:${sig.extra}`);
      else pristine.push(`${sig.verdict}=${sig.sig}:${sig.engine}`);
    }

    for (const wl of this.whitelist) pristine.push(`${wl.whitelist_type}=${wl.value}`);
    
    if (this.cartridge_version) pristine.push("cv="+this.cartridge_version);
    
    if (this.scoring_method) pristine.push("sm="+this.scoring_method);

    if (this.content_class) pristine.push("cc="+this.content_class);

    if (this.no_content) pristine.push("nc="+this.no_content);
    
    if (this.timestamp) pristine.push("ts="+this.timestamp);
    
    if (this.truncated) pristine.push("tr="+this.truncated);

    if (this.priority) pristine.push("po="+this.priority);

    if (this.complete) pristine.push("c="+this.complete);

    if (this.context) pristine.push("cx="+this.context);

    if (this.version) pristine.push("v="+this.version);

    if (this.bulk) pristine.push("b="+this.bulk);

    if (this.mask) pristine.push("m="+this.mask);
    
    this.analysis_string = pristine.join(" ");

    return this.analysis_string;
  }

  getFoldedAnalysisString() {
    const max = 72;
    let wrapped = "";
    let wrap_length = 0;
    let tmp_str = this.getAnalysisString();
    const words = tmp_str.split(' ');
    for (const word of words) {
      if ((wrapped.length - wrap_length + word.length) > max) {
        wrap_length = wrapped.length;
        wrapped += "\r\n  " + word + " ";
      } else {
        wrapped += word + " ";
      }
    }
    return wrapped.trim();
  }
  
  getForcedAnalysisString() {
    let str = this.getAnalysisString();
    //return str;
    return str.replaceAll(/\ba=/g, "p=");
  }
}

class Message extends InputType {
  label = "Message";
  isFullRfc822;
  isBodyOnly;
  analysis;
  content;
  base64 = null;
  error = null;
  type = "text/plain; charset=utf-8";
  name = "message sample";
  containsAnalysisHeader = false;

  constructor(content, isFullRfc822, isBodyOnly) {
    super();
    this.content = content;
    this.isFullRfc822 = isFullRfc822;
    this.isBodyOnly = isBodyOnly;
    this.name += Date.now().toString();
    this.toBase64();
  }

  SetAnalysis(analysis) {
    this.analysis = analysis;
    this.containsAnalysisHeader = this.analysis.analysisLikeness() >= 1;
  }
  
  toBase64() {
    const encoder = new TextEncoder();
    const blob = new Blob([encoder.encode(this.content)])
    const self = this;
    const reader = new FileReader();
    reader.onload = () => { self.base64 = reader.result.substring(reader.result.indexOf(",")+1) };
    reader.onerror = () => { self.error = reader.error };
    reader.readAsDataURL(blob);
  }
}

class MsgFile extends InputType {
  label = "File";
  file;
  content = null;
  error = null;
  name;
  type;
  base64 = null;
  inner = null;

  constructor(file) {
    super();
    const self = this;
    this.file = file;
    this.name = file.name;
    this.type = file.type;
  }

  readFile() {
    return this.file.text();
  }

  toBase64() {
    const blob = this.file;
    const self = this;
    const reader = new FileReader();
    reader.onload = () => { self.base64 = reader.result.substring(reader.result.indexOf(",")+1) };
    reader.onerror = () => { self.error = reader.error };
    reader.readAsDataURL(blob);
  }

  setContent(text) {
    this.content = text;
    this.inner = Input.detectType(text);
    this.toBase64();
  }

}

export {Input as default, InputType, IP, CIDR, AnalysisHeader, Message, MsgFile};
